import { Component, OnInit, OnDestroy, ViewEncapsulation, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Auth } from '@app/models';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MustMatch } from '@app/modules/user/services';
import { ChangeService } from '@app/modules/user/services';
import { ReCaptchaV3Service } from "@node_modules/ng-recaptcha";

@Component({
  selector: 'mjs-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [ChangeService],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  currentAuth: Auth;
  currentAuthSubscription: Subscription;
  content: any;
  loading: boolean;
  id: number;
  timestamp: number;
  token: string;
  form: FormGroup;
  submitted: boolean;
  hide: boolean;
  hideConfirm: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  // Example of activation query parameters.
  // ?id=1&timestamp=1556841733&token=iafl5v4zlqJOVcFGi3OEaX80isGErF2GOihbEj0I_rk
  constructor(private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private changeService: ChangeService,
    private toast: ToastrService,
    private fb: FormBuilder
  ) { }
    

  ngOnInit(): void {
    this.loading = false;

    this.form = this.fb.group({
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])(?=.*\d).{10,}$/)
      ]),
      passwordConfirm: new FormControl(null, Validators.required)
    }, {
      validator: MustMatch('password', 'passwordConfirm')
    });

    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe((paramMap: ParamMap) => {
      const params = paramMap.keys;

      for (let i = 0; i < params.length; i += 1) {
        let key = params[i];
        const param = paramMap.get(key);

        switch (key) {
          case 'id':
          case 'amp;id':
          case 'timestamp':
          case 'amp;timestamp':
            this[key.replace('amp;', '')] = parseInt(param, 10);
            break;
          case 'token':
          case 'amp;token':
            this.token = param;
            break;
          default:

            break;
        }
      }

      if (!this.id && !this.timestamp && !this.token) {
        this.loading = true;
        this.router.navigate(['/user/reset']);
      }
    });
  }

  get f(): any { return this.form.controls; }

  onSubmit(params?: any): any {
    this.submitted = true;
    this.renderer.selectRootElement('#main-content', true).scrollIntoView();

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    const { password } = this.form.value;
    const { id, timestamp, token } = this;

    this.recaptchaV3Service
      .execute('create_alert')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (recaptcha) => {
          this.change(id, timestamp, token, password, recaptcha);
        },
        error: err => {
          this.loading = false;
          this.toast.error('Recaptcha validation failed');
        }
      });
  }

  change(id, timestamp, token, password, recaptcha): void {
    this.changeService
      .patch(id, timestamp, token, password, recaptcha)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (data: any) => {
          return this.router.navigate(['/user/login'], {
            queryParams: {
              message: 'password_changed'
            }
          });
        },
        error: err => {
          this.loading = false;
          this.toast.error(err?.error?.message ?? 'Something went wrong');
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}

