import { Component, OnInit, OnDestroy, ViewEncapsulation, Inject, Renderer2, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DOCUMENT, isPlatformBrowser  } from '@angular/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { MatDialog } from '@angular/material/dialog';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import { CreateAlertComponent } from './components';
import { SavedSearchService } from '@app/services/saved-search.service';
import { EngageAnalyticsService, JobSearchService, FacetService } from './services';
import { DefaultParams, MjsSearchResults, MjsSearchParams } from './models';
import { Content } from '@app/models';
import {
  searchParamsToParams,
  savedSearchToSearchParams,
  paramsToSearchParams,
  paramsToHttpParams
} from './helpers';
import { searchParams } from './search-params';
import { SavedSearch } from '@app/models/saved-search.model';
import { environment } from '@env/environment';
import { NGXLogger } from 'ngx-logger';

declare var lumesseAnalytics: any;

@Component({
  selector: 'mjs-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [JobSearchService, SavedSearchService, EngageAnalyticsService],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit, OnDestroy {

  isMobile: boolean;
  filtersOpen: boolean;
  loading: boolean;
  auto: boolean;
  defaultParams: DefaultParams;
  form: any;
  content: Content;
  jobs: MjsSearchResults;
  changing: string;
  map: boolean;
  token: string;
  isLoggedIn: boolean;
  savedSearchEmail: string;
  params: MjsSearchParams;
  showCreateJobAlertCTA: boolean;
  isBrowser: boolean;
  sType: string;
  cType: string;

  facets: any;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platform: string,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private oauthService: OAuthService,
    private analytics: Angulartics2GoogleAnalytics,
    private toast: ToastrService,
    public breakpointObserver: BreakpointObserver,
    private searchService: JobSearchService,
    private savedSearchService: SavedSearchService,
    private facetService: FacetService,
    private engageAnalytics: EngageAnalyticsService,
    private logger: NGXLogger) {
    this.showCreateJobAlertCTA = environment.showCreateJobAlertCTA;
    this.isBrowser = false;
  }

  ngOnInit(): void {
    this.params = JSON.parse(JSON.stringify(searchParams));

    // If this is a fresh page load the token will already be available when
    // the component bootstraps.
    if (this.token) {
      this.redirectToSavedSearch(this.token);
    }

    this.renderer.addClass(this.doc.body, 'gray');
    this.renderer.addClass(this.doc.body, 'search');

    this.route.data
      // .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.form = data['filters'];
        this.content = data['content'];
        this.loading = false;
        this.auto = true;
        // Default params will be present on single organisation and single category pages
        // and can be configured via the drupal admin if needed.
        if (this.content?.org?.search_meta?.query) {
          this.defaultParams = this.content.org.search_meta.query;
          this.search();
        } else if (this.content?.search_meta?.query) {
          this.defaultParams = this.content.search_meta.query;
          this.search();
        } else {
          this.defaultParams = {};
        }
      });

    if (isPlatformBrowser(this.platform)) {
      this.isBrowser = true;

      this.breakpointObserver
        .observe(['(min-width: 961px)'])
        .subscribe((state: BreakpointState) => {
          this.isMobile = !state.matches;
          this.filtersOpen = !this.isMobile;
        });

      this.isLoggedIn = this.oauthService.hasValidAccessToken();
    }

    // Get values from queryParams and apply to this.params.
    this.route.queryParamMap
      .subscribe((paramMap: ParamMap) => {
        const token = paramMap.get('token');

        if (token) {
          // If this is server side then store the token for use client side.
          if (isPlatformBrowser(this.platform)) {
            this.redirectToSavedSearch(token);
          } else {
            this.token = token;
          }
        }

        this.sType = paramMap.get('sType');
        this.cType = paramMap.get('cType');

        // Convert the raw URL search params to form ready values.
        this.params = searchParamsToParams(this.params, paramMap);

        // If this is an organisation search page and the organisation has a default location
        // set then redirect the user unless they are searching by a location already.
        // This was a client request which we advised against.
        if (this.content?.org?.path) {
          if (this.content?.org?.field_default_location?.latitude) {
            if (!paramMap.get('distance') && !paramMap.get('location') && !paramMap.get('latlng')) {
              const { location, latitude, longitude } = this.content.org.field_default_location;
              this.params.location.value = location;
              this.params.latlng.value = `${latitude},${longitude}`;
              this.params.distance.value = Number(this.content.org?.field_default_radius ?? 10);

              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([this.content.org.path + "/jobs"], {
                queryParams: paramsToSearchParams(this.params, this.defaultParams),
                replaceUrl: true
              }));
            }
          }
        }

        this.search();
      });
  }

  /**
   * Fetch a saved search by token and redirect user.
   *
   * @param string token
   */
  redirectToSavedSearch(token: string): void {
    this.savedSearchService.get(token)
      // .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (savedSearch: SavedSearch): any => {
          // Redirect sliently to / then back to search otherwise the component will not update.
          this.router.navigateByUrl('/', {
            skipLocationChange: true
          }).then(() => this.router.navigate(['/search'], {
            queryParams: savedSearchToSearchParams(savedSearch),
            replaceUrl: true
          }));
        },
        error: err => {
          this.toast.error(`We were unable to load the saved search`);
        }
      });
  }

  search(): void {
    this.loading = true;

    const searchParams = paramsToHttpParams(this.params, this.defaultParams);

    this.analytics.eventTrack(searchParams.searchAnalytics, {
      category: 'Job Search',
      label: 'Query Parameters'
    });

    this.searchService.get(searchParams.httpParams)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (data: MjsSearchResults) => this.processSearchResults(data),
        error: () => this.loading = false
      });
  }

  processSearchResults(data: MjsSearchResults): void {
    if (this.content?.path.substring(0, 12) === '/partnership') {
      let list = data.list.map(item => {
        item.partnership = true;
        return item;
      });

      this.jobs = { ...data, list };
    } else {
      this.jobs = data;
    }

    this.loading = false;

    this.facets = data?.facets;
    // this.facetService.changeValue({ facets: data?.facets ?? {}, changing: this.changing, params: this.params });

    this.facetService.changeValue({
      facets: data.facets,
      changing: this.changing,
      params: this.params
    });

    if (isPlatformBrowser(this.platform)) {
      this.engageAnalytics.post({ params: this.params, default_params: this.defaultParams })
        // .pipe(takeUntil(this.onDestroy$))
        .subscribe((data: any) => {
          if(typeof lumesseAnalytics != "undefined") {
            lumesseAnalytics.pushEvent('SearchResultsViewed', data);
          }
        });
    }
  }

  unlockFacets(): void {
    this.changing = '';
  }

  trackByJobId(job) {
    return job.id;
  }

  onCheckboxChange(input: any): void {
    this.params[input.key].value = input.selected;
    this.changing = input.key;
    this.params.page.value = 1;

    if (!input.selected && !input.parents) {
      this.changing = '';
    }

    if (this.params[`parent_${input.key}`]) {
      this.params[`parent_${input.key}`].value = input.parents;
    }

    const routeParams = paramsToSearchParams(this.params, this.defaultParams);

    this.router.navigate([this.router.url.split('?')[0]], {
      relativeTo: this.route,
      queryParams: routeParams,
      state: { ignoreLoadingBar: true }
    });
  }

  toggleMap = (): void => {
    this.map = !this.map;

    if (this.map) {
      this.renderer.addClass(this.doc.body, 'full-screen-map');
    } else {
      this.renderer.removeClass(this.doc.body, 'full-screen-map');
    }
  }

  createAlert() {
    const dialogRef = this.dialog.open(CreateAlertComponent, {
      panelClass: 'mjs-search-create-alert-dialog',
      data: {
        queryParams: this.params,
        defaultParams: this.defaultParams,
        user: this.oauthService.hasValidAccessToken()
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('id')) {
        this.toast.success('Your job alert has been created')
      }
    });
  }

  showAlertButton(): boolean {
    if (!this.showCreateJobAlertCTA) {
      return false;
    }

    if (this.map) {
      return false;
    }

    // Return true if any default params are present.
    for (let key in this.defaultParams) {
      return true;
    }

    for (let key in this.params) {
      // Skip params that are not relevant to saved searches.
      if (['type', 'sort', 'page'].indexOf(key) > -1) {
        continue;
      }

      switch (this.params[key]['type']) {
        case 'numberArray':
        case 'stringArray':
          if (this.params[key]['value'].length > 0) {
            return true;
          }
          break;
        case 'string':
        case 'number':
          if (this.params[key]['value']) {
            return true;
          }
          break;
      }
    }

    return false;
  }

  get refreshYourCareerMessage(): string {
    if (this.params['refresh_your_career'].value === 1) {
      return `<p>Are you new to or returning to work? or maybe at a career crossroads and looking for a change? This type of role may suit someone who is looking to make a new start, transfer their skills, and learn and develop for the future.</p><p>Apply now and let us know what you have to offer, and we’ll be sure to consider your application.</p><p class="mb-0">For top tips in completing your application form click here to <a class="font-weight-bold" href="/career-hub/how-apply" target="_blank">check out our Career Hub page on how to apply</a>.</p>`;
    }

    return '';
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.doc.body, 'gray');
    this.renderer.removeClass(this.doc.body, 'search');
    this.renderer.removeClass(this.doc.body, 'full-screen-map');
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
