import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { FavouritesService } from '@app/modules/user/services/favourites.service';
import { WebpService } from '@app/services';
import { JobPreview } from '@app/models';
import { MjsJob } from '@app/models/mjs-job.model';

@Component({
  selector: 'mjs-job-listing',
  templateUrl: './job-listing.component.html',
  styleUrls: ['./job-listing.component.scss'],
  providers: [FavouritesService],
  encapsulation: ViewEncapsulation.None
})
export class JobListingComponent {

  @Input() mjsJob: MjsJob;
  @Input() sType: string;
  @Input() cType: string;

  locationsOpen: boolean;

  constructor(private oauthService: OAuthService,
    private toast: ToastrService,
    private favourites: FavouritesService,
    public webp: WebpService) {
  }

  get locationsName(): string[] {
    if (this.mjsJob?.locations_name) {
      return this.mjsJob.locations_name.split('---');
    }
    return [];
  }

  favourite(): void {
    const state = this.mjsJob.user_like;

    if (this.oauthService.hasValidAccessToken()) {
      this.favourites.patch(this.mjsJob.id).subscribe({
        next: (data: any) => {
          this.mjsJob.user_like = !state;
          this.toast.success(data.message)
        },
        error: _ => this.signInMessage()
      });
    } else {
      this.signInMessage();
    }
  }

  signInMessage() {
    this.toast.error('You need to sign in to add favourites. <a href="/user/login">Click here</a> to sign in.');
  }

  ellipsis = (input: string): string => {
    if (input.length >= 36) {
      return `${input.substring(0, 36)}...`;
    }

    return input;
  }

  get jobQueryParams() {
    const params: any = {};

    if (this.mjsJob.partnership) {
      params.partnership = true;
    }

    if (this.sType) {
      params.sType = this.sType;
    }

    if (this.cType) {
      params.cType = this.cType;
    }

    return params;
  }
}
