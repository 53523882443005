import {
  Component,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
  Input,
  Output, OnDestroy
} from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { MjsSearchFilter, MjsSearchParams } from "@modules/search/models";
import { FacetService } from "@modules/search/services";
import { paramsToSearchParams } from "@modules/search/helpers";

@Component({
  selector: 'mjs-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BooleanComponent implements OnInit, OnDestroy {

  @Input() data: MjsSearchFilter;
  @Input() params: MjsSearchParams;
  @Input() defaultParams: any;
  @Input() static: boolean;
  @Input() org: any;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() unlockFacets: EventEmitter<any> = new EventEmitter();

  subscription: Subscription;
  form: FormGroup;
  facets: any;
  changing: string = '';
  init: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private facetService: FacetService,
    private route: ActivatedRoute,
    private router: Router) {
    if (!this.static) {
      this.subscription = this.facetService.getFacets()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(res => {
          this.facets = res.facets;
          this.changing = res.changing;
        });
    }
  }

  ngOnInit(): void {
    this.form = new FormGroup({ });

    for (let option in this.data['options']) {
      this.form.addControl(this.data['name'] + '__' + option, new FormControl(this.params[this.data['name']].value ? true : false, []));
    }

    this.init = true;

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(values => {
        for (let key in values) {
          this.params[key.split('__')[0]].value = values[key] ? 1 : 0;
        }

        const routeParams = paramsToSearchParams(this.params, this.defaultParams);

        this.router.navigate([this.router.url.split('?')[0]], {
          relativeTo: this.route,
          queryParams: routeParams,
          state: { ignoreLoadingBar: true }
        });
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
