import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Angulartics2GoogleAnalytics } from "@node_modules/angulartics2";
import { MjsJobMetricsService } from "@app/services/mjs-job-metrics.service";
import { MjsJob } from "@models/mjs-job.model";
import { environment } from "@env/environment";
import { ParamMap } from "@node_modules/@angular/router";
import { isPlatformBrowser } from "@node_modules/@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { FavouriteService } from "@app/shared";
import { OAuthService } from "angular-oauth2-oidc";
import { LumesseService } from "@app/services/lumesse.service";

declare var window: any;

@Component({
  selector: 'mjs-apply-button',
  templateUrl: './apply-button.component.html',
  styleUrls: ['./apply-button.component.scss'],
  providers: [MjsJobMetricsService]
})
export class ApplyButtonComponent implements OnInit, OnDestroy {

  @Input() mjsJob: MjsJob;
  @Input() style?: 'minimal' | 'full';

  buttonText: string;
  buttonActive: boolean;
  description: string;
  viewLink: boolean;
  featured: boolean;
  partnership: boolean;
  emailOnlyAccount: boolean;
  identifier: string;
  lumesseStatus: any;
  sType: string;
  cType: string;

  private onDestroy$: Subject<void> = new Subject<void>();
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
    private analytics: Angulartics2GoogleAnalytics,
    private metricsService: MjsJobMetricsService,
    private lumesse: LumesseService) {
    this.buttonText = '';
    this.buttonActive = false;
    this.description = '';
    this.viewLink = false;
  }

  ngOnInit() {
    this.identifier = this.mjsJob.job_number || this.mjsJob.id;

    this.route.queryParamMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((paramMap: ParamMap) => {
        const jobAction = paramMap.get('job_action');
        this.featured = this.style === 'minimal' ? false : paramMap.get('featured') ? true : false;
        this.partnership = paramMap.get('partnership') ? true : false;
        this.emailOnlyAccount = paramMap.get('eoa') ? true : false;
        this.sType = paramMap.get('sType');
        this.cType = paramMap.get('cType');

        if (!this.sType) {
          this.sType = 'myjobscotland';
        }
      });

    // Is the job accepting applications?
    if (isPlatformBrowser(this.platformId)) {
      const now = new Date();
      const startDate = new Date(Date.parse(this.mjsJob.start_date.replace(' ', 'T')));
      const endDate = new Date(Date.parse(this.mjsJob.end_date.replace(' ', 'T')));
      this.lumesse.currentValue.subscribe(res => this.lumesseStatus = res);
      if (this.mjsJob.allow_applications === '1') {
        // Is the job closed?
        if (endDate <= now) {
          if (this.style === 'minimal') {
            this.viewLink = true;
          } else {
            this.buttonText = 'Applications Closed';
            this.buttonActive = false;
            this.description = 'This position has expired.';
          }
          // Is the job open?
        } else if (now > startDate) {
          switch (this.mjsJob.source) {
            // Did the job originate on Drupal?
            case 'webform':
            case 'api':
              if (this.style === 'minimal') {
                this.viewLink = true;
              } else {
                if (this.mjsJob.external_application_url) {
                  this.buttonText = `Apply${this.style === 'full' ? ' now' : ''}`;
                  this.buttonActive = true;
                  this.description = 'The application page will open in a new window.';
                } else {
                  this.buttonText = `Applications disabled`;
                  this.buttonActive = false;
                  this.description = 'Applications for this job cannot be made online. Please refer to the advert for further information.';
                }
              }
              break;
            // Did the job originate on Talentlink?
            case 'cornerstone':
              if (!this.lumesseStatus || this.lumesseStatus.lumesse_disable) {
                if (this.style === 'minimal') {
                  this.viewLink = true;
                } else {
                  this.buttonText = 'Applications temporarily unavailable';
                  this.buttonActive = false;
                  this.description = 'The job application process is currently not available, due to problems communicating with the Lumesse server.';
                }
              } else {
                this.buttonText = `Apply${this.style === 'full' ? ' now' : ''}`;
                this.buttonActive = true;
                this.description = '';
              }
              break;
            default:
          }
        } else {
          if (this.style === 'minimal') {
            this.viewLink = true;
          } else {
            this.buttonText = 'Applications Closed';
            this.buttonActive = false;
            this.description = `This position will open on ${startDate.getMonth().toString().padStart(2, '0')}/${startDate.getDay().toString().padStart(2, '0')}/${startDate.getFullYear()}.`;
          }
        }
      } else {
        if (this.style === 'minimal') {
          this.viewLink = true;
        } else {
          this.buttonText = 'Applications disabled';
          this.buttonActive = false;
          this.description = 'Applications for this job cannot be made online. Please refer to the advert for further information.';
        }
      }
    }
  }

  apply(): any {
    let path = this.mjsJob.url.split('/').reverse()[0];

    this.metricsService.apply(parseInt(this.mjsJob.id, 10), this.mjsJob.featured === '1')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();

    this.analytics.eventTrack('click', {
      category: 'Apply',
      label: this.mjsJob.job_number || this.mjsJob.id,
      noninteraction: true
    });

    window.gtag('event', 'Apply', {
      label: this.identifier,
    });

    if (this.emailOnlyAccount) {
      // GA4 event.
      window.gtag('event', 'Apply by Email Only Account', {
        label: this.identifier,
      });

      this.analytics.eventTrack('click', {
        category: 'Apply by Email Only Account',
        label: this.identifier,
        noninteraction: true
      });
    }

    let url = '';
    let external = false;

    if (this.mjsJob.source == 'cornerstone') {
      if (window.lumesse) {
        return window.location.href = `/rac?jobId=${environment.lumesse_technical_id}-${this.mjsJob.talentlink_job_id}&sType=${this.sType}${this.cType ? '&cType=' + this.cType : ''}`;
      } else {
        const queryParams: any = {
          jobId: `${environment.lumesse_technical_id}-${this.mjsJob.talentlink_job_id}`,
          sType: this.sType
        };

        if (this.cType) {
          queryParams.cType = this.cType;
        }

        return this.router.navigate(['/rac'], {queryParams});
      }
    } else {
      if (this.mjsJob.external_application_url) {
        url = this.mjsJob.external_application_url;
        external = true;
      }
    }

    if (this.oauthService.hasValidAccessToken() && !this.mjsJob.external_application_url) {
      url += `?token=${this.oauthService.getAccessToken()}&ngsw-bypass`;
    }

    if (url) {
      if (external) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
