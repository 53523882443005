import { Inject, Component, OnInit, OnDestroy, ViewEncapsulation, Renderer2, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { OAuthService } from 'angular-oauth2-oidc';
import { SearchesService } from '@app/modules/user/services';
import { SavedSearchService } from '@app/services/saved-search.service';
import { searchParams } from '@app/modules/search/search-params';

@Component({
  selector: 'mjs-saved-search',
  templateUrl: './saved-search.component.html',
  styleUrls: ['./saved-search.component.scss'],
  providers: [SearchesService, SavedSearchService],
  encapsulation: ViewEncapsulation.None
})
export class SavedSearchComponent implements OnInit, OnDestroy {
  
  content: any;
  filters: any;
  data: any;
  submitted: boolean;
  loading: boolean;
  params: any;
  form: any;
  defaultParams = <any>{};
  title: string;
  name: string;
  consented: boolean;
  send: boolean;
  token: string;
  loaded: boolean;
  profile: any;
  recruiter: boolean;
  showDistance: boolean = true;
  userId: number;
  savedSearch: any;
  auto: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private searchesService: SearchesService,
    private savedSearchService: SavedSearchService,
    private renderer: Renderer2,
    private router: Router,
    private oauthService: OAuthService) {

    this.params = JSON.parse(JSON.stringify(searchParams));
  }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      const profile = <any>this.oauthService.getIdentityClaims();
      this.userId = profile.uid;

      if (profile.admin_menu) {
        this.recruiter = true;
      }
    }

    this.route.data.subscribe(res => {
      this.content = res['content'];
      this.form = res['filters'];
    });

    this.loading = true;

    // Get values from queryParams and apply to this.params.
    this.route.queryParamMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((paramMap: ParamMap) => {
        const params = paramMap.keys;

        let isEdit = false;

        for (let i = 0; i < params.length; i += 1) {
          let key = params[i];
          const param = paramMap.get(key);

          if (key === 'token') {
            isEdit = true;

            if (isPlatformBrowser(this.platform)) {
              this.savedSearchService.get(param).subscribe({
                next: (data: any) => {
                  this.savedSearch = data;
                  this.content.title = 'Edit Saved Search';
                  this.name = data.name;
                  this.consented = data.consented ? true : false;
                  this.send = data.send;
                  this.token = param;

                  if (data.query_params) {
                    for (let paramKey in data.query_params) {
                      if (this.params.hasOwnProperty(paramKey)) {
                        this.params[paramKey].value = data.query_params[paramKey];

                        if (data.query_params[paramKey]) {
                          this.params[paramKey].value = data.query_params[paramKey];
                        }
                      }
                    }
                  }

                  this.loaded = true;
                  this.loading = false;
                },
                error: err => {
                  this.loaded = true;
                  this.loading = false;
                }
              });
            }
          }

        }

        if (!isEdit) {
          this.loaded = true;
          this.loading = false;
        }
      });
  }

  delete(element): any {
    element.confirm = false;

    if (this.userId !== element.user_id) {
      this.toast.error(`You can only delete your own saved searches`);
      this.loading = false;
      return;
    }

    this.searchesService.delete(element.id).pipe(takeUntil(this.onDestroy$)).subscribe({
      next: data => {
        this.loading = false;
        this.data = data;

        for (let i = 0; i < this.data.list.length; i += 1) {
          if (element.id === this.data.list[i].id) {
            this.data.list = this.data.list.splice(i, 1);
          }
        }
      },
      error: err => {
        this.loading = false;
        let message = 'Sorry, something went wrong';

        if (err.error) {
          message = err.error
        }

        if (err.error && err.error.error) {
          message = err.error.error
        }

        if (err.error && err.error.message) {
          message = err.error.message;
        }

        this.toast.error(message);
      }
    });
  }

  confirm(element) {
    element.confirm = true;
  }

  cancel(element) {
    element.confirm = false;
  }

  unlockFacets() {
  }

  unlock() {

  }

  onCheckboxChange(input) {
    const { key, parents, selected } = input

    if (input.parents.length > 0) {
      this.params[`parent_${key}`].value = parents;
    } else {
      if (this.params[`parent_${key}`]) {
        this.params[`parent_${key}`].value = [];
      }
    }

    this.params[key].value = selected;
  }

  submit(): any {
    this.loading = true;
    const params = {};
    this.renderer.selectRootElement('#main-content', true).scrollIntoView();

    for (let key in this.params) {
      if (!this.params[key].value) {
        continue;
      }

      params[key] = this.params[key].value;
    }

    if (!this.consented) {
      this.loading = false;
      return this.toast.error('You must explicitly consent to us storing your job search and/or sending you a job alert email. The consent tick box is at the bottom of the screen.');
    }

    if (!this.send) {
      this.loading = false;
      return this.toast.error('You must indicate if you wish to receive email alerts.');
    }

    const data = {
      query: params,
      name: this.name,
      consented: this.consented,
      send: this.send || 0
    }

    if (this.token) {
      if (this.userId !== this.savedSearch.user_id) {
        this.toast.error(`You can only update your own saved searches`);
        this.loading = false;
        return;
      }

      this.searchesService.patch(data, this.token).pipe(takeUntil(this.onDestroy$)).subscribe({
        next: data => {
          this.toast.success('Your saved search has been saved');
          this.loading = false;

          this.router.navigate(['/user/alerts']);
        },
        error: err => {
          this.loading = false;
          let message = 'Sorry, something went wrong';

          if (err.error) {
            message = err.error
          }

          if (err.error && err.error.error) {
            message = err.error.error
          }

          if (err.error && err.error.message) {
            message = err.error.message;
          }

          this.toast.error(message);
        }

      });
    }
    else {
      this.searchesService.post(data).pipe(takeUntil(this.onDestroy$)).subscribe({
        next: data => {
          this.toast.success('Your saved search has been created');
          this.content.title = 'Edit Saved Search';
          this.loading = false;
          this.router.navigate(['/user/alerts']);
        },
        error: err => {
          this.loading = false;
          let message = 'Sorry, something went wrong';

          if (err.error) {
            message = err.error
          }

          if (err.error && err.error.error) {
            message = err.error.error
          }

          if (err.error && err.error.message) {
            message = err.error.message;
          }

          this.toast.error(message);
        }
      });
    }
  }

  updateLocation(event) {
    this.params.latlng.value = event.latlng;
    this.params.location.value = event.location;
    this.params.distance.value = event.distance;
  }

  updateKeywords(event) {
    this.params.keywords.value = event;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
