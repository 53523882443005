<mjs-page-header [content]="content" [size]="'small'" [toggleMap]="toggleMap"></mjs-page-header>
<mjs-search-header
  [isMobile]="isMobile"
  [content]="content"
  [jobs]="jobs"
  [params]="params"
  [loading]="loading"
  [auto]="auto"
  [map]="map"
  (unlockFacets)="unlockFacets()"
  (toggleMap)="toggleMap()"
  [isBrowser]="isBrowser">
</mjs-search-header>
<div class="container">
  <div class="row">
    <ng-container *ngIf="isMobile">
      <div class="mobile-filter-toggle text-center">
        <button
          class="btn btn-primary d-block w-100"
          tabindex="0"
          (click)="this.filtersOpen = !this.filtersOpen">
          <ng-container *ngIf="filtersOpen">
            <ng-container *ngIf="loading">Searching...</ng-container>
            <ng-container *ngIf="!loading && jobs.total == 0">No jobs found</ng-container>
            <ng-container *ngIf="!loading && jobs.total != 0">
              View {{ jobs.total }} job{{ jobs.total == 1 ? '' : 's' }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!filtersOpen">Filters</ng-container>
          <svg *ngIf="!filtersOpen" width="18" height="18" viewBox="0 0 1024 1024"><path fill="#fff" d="M213.333 426.667v-298.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v298.667c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM554.667 896v-384c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v384c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM896 512v-384c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v384c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM42.667 640h85.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h85.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM384 384h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-85.333v-170.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v170.667h-85.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM725.333 725.333h85.333v170.667c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-170.667h85.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path></svg>
        </button>

      </div>

    </ng-container>
    <div class="col sidebar"
      [ngClass]="{
        mobile: isMobile,
        open: filtersOpen,
        map: map
      }"
      [attr.aria-hidden]="filtersOpen ? 'false' : 'true'">
      <span [ngClass]="{
        'd-flex': isMobile,
        'mobile-filter': isMobile,
        'flex-column': isMobile,
        'justify-content-center': isMobile
      }">
        <div class="d-flex flex-column" *ngIf="isBrowser && isMobile">
          <p class="sr-only">Interact with the following form fields to trigger a search of all jobs.</p>
          <mjs-keywords
            [keywords]="params.keywords.value"
            [auto]="auto"
            (unlockFacets)="unlockFacets()">
          </mjs-keywords>
          <mjs-location
            [latlng]="params.latlng.value"
            [location]="params.location.value"
            [distance]="params.distance.value ? params.distance.value.toString() : ''"
            [auto]="auto"
            (unlockFacets)="unlockFacets()">
          </mjs-location>
          <mjs-sort [selected]="params.sort.value"></mjs-sort>
        </div>
        <a
          href="./#search-results"
          onclick="event.preventDefault(); location.hash='search-results';"
          class="mjs-skip-to-search-results btn btn-primary d-block">
          Skip to search results
        </a>
        <ng-container *ngFor="let item of form | keyvalue | orderBy: 'value.weight'">
          <mjs-checkboxes
            *ngIf="item.value.type === 'checkbox-group'"
            [data]="item.value"
            [org]="this.content && this.content.org ? this.content.org : null"
            [params]="params"
            [defaultParams]="defaultParams"
            (valueChange)="onCheckboxChange($event)"
            (unlockFacets)="unlockFacets()">
          </mjs-checkboxes>
          <mjs-boolean
            *ngIf="item.value.type === 'boolean'"
            [data]="item.value"
            [org]="this.content && this.content.org ? this.content.org : null"
            [params]="params"
            [defaultParams]="defaultParams"
            (unlockFacets)="unlockFacets()">
          </mjs-boolean>
        </ng-container>

        <mjs-advert [type]="'doublempu'"></mjs-advert>
      </span>
    </div>
    <div class="col" id="search-results mb-5">

      <mjs-loader *ngIf="loading"></mjs-loader>

      <ng-container *ngIf="map">

        <mjs-apple-map [list]="jobs ? jobs.list : []"></mjs-apple-map>

        <mjs-pager
          *ngIf="jobs && jobs.pages > 0"
          [page]="jobs.page"
          [pages]="jobs.pages"
          [total]="jobs.total">
        </mjs-pager>

      </ng-container>

      <ng-container *ngIf="!loading && !map">

        <div class="search-body" *ngIf="content.body">
          <mjs-read-more [data]="content.body" [preview]="240"></mjs-read-more>
        </div>

        <div *ngIf="showAlertButton()"
             class="mjs-search-create-alert-cta bg-white rounded mb-2 w-100 position-relative p-2 border border-primary shadow-sm d-flex justify-content-between align-items-center">
          <p class="font-weight-bold m-0 pl-1">Get job alerts for your search emailed to you</p>
          <button class="btn btn-outline-primary stretched-link" (click)="createAlert()">
            Create alert
          </button>
        </div>

        <div class="bg-white rounded mb-2 w-100 position-relative border border-primary shadow-sm py-2 px-3"
             *ngIf="refreshYourCareerMessage">
          <div [innerHTML]="refreshYourCareerMessage"></div>
        </div>

        <ng-container *ngIf="jobs && jobs.list.length > 0">

          <mjs-job-listing
            *ngFor="let mjsJob of jobs.list trackBy: trackByJobId"
            [mjsJob]="mjsJob"
            [sType]="sType"
            [cType]="cType">
          </mjs-job-listing>

          <div *ngIf="showAlertButton() && (jobs && jobs.list.length > 5)"
               class="mjs-search-create-alert-cta bg-white rounded mb-2 w-100 position-relative p-2 border border-primary shadow-sm d-flex justify-content-between align-items-center">
            <p class="font-weight-bold m-0 pl-1">Get job alerts for your search emailed to you</p>
            <button class="btn btn-outline-primary stretched-link" (click)="createAlert()">
              Create alert
            </button>
          </div>

          <mjs-pager
            *ngIf="jobs && jobs.pages > 0"
            [page]="jobs.page"
            [pages]="jobs.pages"
            [total]="jobs.total">
          </mjs-pager>

        </ng-container>

        <ng-container *ngIf="jobs && jobs.list.length < 1">
          <p class="no-results">Vacancies matching your search are normally shown here if they are currently published. If you are sure the vacancy you are looking for exists then widen your results by removing filters or begin a new search.</p>
        </ng-container>

        <mjs-loader [hidden]="!loading" *ngIf="!loading"></mjs-loader>

      </ng-container>

    </div>

  </div>

</div>
