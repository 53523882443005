<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="container">
  <div class="row">
    <main class="col" #main>
    <mjs-loader *ngIf="loading"></mjs-loader>
    <div [innerHtml]="content.body" *ngIf="content.body && !loading"></div>
      <mjs-entity-form
        *ngIf="!loading"
        entity="taxonomy_term"
        bundle="organisation"
        mode="create_organisation"
        [params]="params"
        (submitForm)="submit($event)"
      ></mjs-entity-form>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>