import { Component, ViewEncapsulation, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { ParamsService } from '../../services/params.service';
import { OrganisationService } from '../../services/organisation.service';

@Component({
  selector: 'mjs-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  providers: [ParamsService, OrganisationService],
  encapsulation: ViewEncapsulation.None
})
export class CreateComponent implements OnInit {
  
  @ViewChild('main') private main: ElementRef;

  params: any = {};
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private paramsService: ParamsService,
    private organisationService: OrganisationService,
    private toast: ToastrService,
    private oauthService: OAuthService) {
  }

  public ngOnInit() {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe(queryParams => {
      let org_type = queryParams.get('org_type');

      if (org_type) {
        this.params.field_organisation_type = org_type;
      } else { }
    });
  }

  public submit(data: any): void {
    this.loading = true;
    this.params = data.params;
    this.main.nativeElement.scrollIntoView();
    const body = this.paramsService.format(data.params, data.structure, 'organisation');

    this.organisationService.post(body)
      .pipe(takeUntil(this.onDestroy$)).subscribe(
      (res: any) => {
        this.oauthService.refreshToken().then(data => {
          this.oauthService.loadUserProfile().then((data: any) => {
            this.toast.success('Your organisation has been created and will be reviewed by Cosla');
            this.router.navigate(['/my-organisations/' + res.path.split('/').reverse()[0]]);
          })
        })
      },
      err => {
        this.loading = false;
        this.toast.error(err.error && err.error.message ? err.error.message : 'Sorry, something went wrong');
      }
    );
  }
}
